import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './List.module.scss';
import Item from './Item';
import { noticeActions } from '../../../actions';

const cx = classNames.bind(styles);

class Notice extends Component {

  componentDidMount() {
    if (this.props.noticeList.data.length === 0) {
      this.props.actions.noticeActions.request();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure } = nextProps.noticeList;
    return !isProcessing && !failure;
  }

  render() {
    const { noticeList, id } = this.props;

    return (
      <div className={cx('container')}>
        <Container>
          <Row>
            <Col xs={12} md={8} offset={{ md: 2 }}>
              {noticeList.data.map((notice, i) => {
                const isOpen = id === String(notice.id);

                return (
                  <Item key={String(notice.id)} item={notice} isOpen={isOpen} />
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { noticeList } = state;
  return {
    noticeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      noticeActions: bindActionCreators(noticeActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notice);