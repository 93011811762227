import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import classNames from 'classnames/bind';
import moment from 'moment';
import Ionicon from 'react-ionicons';

import styles from './Item.module.scss';

const cx = classNames.bind(styles);

export default class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {    
    this.setState({
      isOpen: nextProps.isOpen,
    });
  }

  render() {
    const { isOpen } = this.state;
    const { id, open_date, title, image } = this.props.item;
    
    return (
      <div className={cx(['container', { open: isOpen }])} onClick={this.toggle.bind(this, id)}>
        <div className={cx('header')}>
          <div className={cx('left')}>
            <div className={cx('date')}>{moment(open_date).format('YYYY.MM.DD')}</div>
            <div className={cx('title')}>{title}</div>
          </div>
          <Ionicon icon={this.arrowIcon(isOpen)} fontSize="17px" />
        </div>
        {isOpen && (
          <div className={cx('content')}>
            <img src={image.url} alt={title} />
          </div>
        )}
      </div>
    );
  }

  toggle = (id) => {
    const { isOpen } = this.state;

    if (isOpen) {
      navigate('/news/notice/');
    }
    else {
      navigate(`/news/notice/?${id}`);
    }
  }

  arrowIcon = (isOpen) => {
    return isOpen ? 'md-remove' : 'md-add';
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};

Item.defaultProps = {
  isOpen: false,
};